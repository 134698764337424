function Drupal() {
    return (
        <><div className='row'>
            <div className='col-xs-12 col-lg-6'>
                <h2>Baylor College of Medicine</h2>
                <p><strong>Link</strong>: <a href='https://bcm.edu' target='_blank'>https://bcm.edu</a></p>
                <p><strong>Host</strong>: <img className="host" src="Acquia.png" alt="acquia" /></p>
                <p>Baylor College of Medicine was based on Drupal 8/9 using Sketch as the guidelines for the final product. I was able to produce the entire front-end before the deadline leaving additional room for enhancements.</p>
                <h2 className="accordion-header" id="headingBaylor">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBaylor" aria-expanded="true" aria-controls="collapseBaylor">
                        Read More!
                    </button>
                </h2>
            </div>
            <div className='col-xs-12 col-lg-6'>
                <a href="bcm-edu.jpg" target="_blank"><img className="project-thumb" src="bcm-laptop.png" /></a>
            </div>
            <div className='col-xs-12'>
                <div className="accordion accordion-flush" id="accordionBaylor">
                        <div className="accordion-item">
                            <div id="collapseBaylor" className="accordion-collapse collapse" aria-labelledby="headingBaylor" data-bs-parent="#accordionBaylor">
                                <div className="accordion-body">
                                    <p className="bold"><strong>Technologies used</strong>: Drupal 8/9 CMS</p>
                                    <p><strong>Frontend Technologies</strong>: PHP, HTML, CSS, Bootstrap, Javascript, jQuery, Google Geolocation services api incorporated into a SPA React component focused on a clustered map solution. AWS for CDN Services</p>
                                    <p><strong>Development Stack used</strong>:</p>
                                    <p>Docker on LAMP stack, Drush, Acquia Cloud Services, AWS CDN</p>
                                    <p><strong>Progression of Influence</strong></p>
                                    <ul>
                                        <li>Created and skinned theme based on Sketch design and Bootstrap 4/5</li>
                                        <li>Created Templates on the TWIG level and Components using the paragraphs module with custom template overrides.</li>
                                        <li>Contributed to Acquia cloud environments including stage, dev, and productions sites rolling out tagged code to necessary sites.</li>
                                        <li>Created Templates on the TWIG level and Components using the paragraphs module with custom template overrides.</li>
                                        <li>Contributed to a PHP menu traversal system as a solution for large menus (10,000+ items) that was breaking the design standards.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row margin-top'>
                <div className='col-xs-12 col-lg-6'>
                    <a href="boardwalk.jpg" target="_blank"><img className="project-thumb" src="boardwalk-leptop.png" /></a>
                </div>
                <div className='col-xs-12 col-lg-6'>
                    <h2>Boardwalk Town Lake</h2>
                    <p><strong>Link</strong>: <a href='https://boardwalktl.com/' target="_blank">https://boardwalktl.com</a></p>
                    <p><strong>Host</strong>: <img className="host" src="pantheon.png" alt="pantheon" /></p>
                    <p>Boardwalk Town Lake was built  Drupal 9 using Figma as the visual reference for the final product. I was able achieve the goal of site completion with amazing results.</p>
                    <h2 className="accordion-header" id="headingBoardwalk">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBoardwalk" aria-expanded="true" aria-controls="collapseBoardwalk">
                            Read More!
                        </button>
                    </h2>
                </div>
                <div className='col-xs-12'>
                    <div className="accordion accordion-flush" id="accordionBoardwalk">
                        <div className="accordion-item">
                            <div id="collapseBoardwalk" className="accordion-collapse collapse" aria-labelledby="headingBoardwalk" data-bs-parent="#accordionBoardwalk">
                                <div className="accordion-body">
                                <p><strong>Development Stack used</strong>:</p>
                                <p>Docksal on LAMP stack, Drush, Pantheon</p>
                                <p><strong>Technologies used</strong>: Drupal 9 CMS</p>
                                    <p>Bordwalk of Townlake was a website redesign project using Drupal.  The existing project had previously seen involvement from multiple developers without reaching completion. My primary objective was to bring this project to a final deliverable.</p>
                                    <p>Within the project's scope, I tackled the task of repairing the Pantheon Pipeline, seamlessly integrating the prior work, all while maintaining a steady pace of code production. This involved not only resolving existing issues but also optimizing the development process to ensure an efficient workflow. In the end, my efforts culminated into an inspiring and user friendly website, marking a significant milestone in the project's journey.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>   
    );   
}

export default Drupal;