function Wordpress() {
    return (
        <div className='row'>
            <div className='col-xs-12 col-lg-6'>
                <h3>San Martin Baby | Dignity Health</h3>
                <p><a href="https:/sanmartinbaby.org" target="/ blank">https://sanmartingbaby.org</a></p>
                <p><strong>Host</strong>: <img id="aws" src="aws-white.png" alt="aws" /></p>
                <p>San Martin Baby was a custom Wordpress solution involving custom Guttenberg components preserving site branding standards regardless of authors.</p>
                <h2 className="accordion-header" id="headingSanMartin">
                    <button className="accordion-button lead collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSanMartin" aria-expanded="true" aria-controls="collapseSanMartin">
                        Read More!
                    </button>
                </h2>
            </div>        
            <div className='col-xs-12 col-lg-6'>
                <a href="sanmartin.jpg" target="_blank"><img  className="project-thumb" src="sanmartin-laptop.png" alt="san-martin" /></a>
            </div>
            <div className='col-xs-12'>
                <div className="accordion accordion-flush" id="accordionSanMartin">
                    <div className="accordion-item">
                        <div id="collapseSanMartin" className="accordion-collapse collapse" aria-labelledby="headingSanMartin" data-bs-parent="#accordionSanMartin">
                            <div className="accordion-body">
                                <p><strong>Development Stack used</strong>:</p>
                                <p>Docker on LAMP stack, Amazon Cloud Services</p>
                                <p className="bold">Content Management Solution:</p>
                                <ul>
                                    <li><img className="host" src="wplogo.png" alt="wordpress" /></li>
                                </ul>
                                <p><strong>Hosts</strong>: Amazon Web Services in combination with Cloudflare.</p>
                                <p>During the development process of SanMartinBaby.org, I engaged in both front-end and back-end development, commencing with the inception of the project as presented through an initial concept on InVision. My primary objective was to create a robust and highly customized web solution, catering to the unique requirements of the platform. A significant aspect of my work involved crafting custom native Gutenberg components, meticulously designed and optimized to seamlessly integrate with the WordPress content management system. This involved leveraging the power of Gutenberg's block editor to create dynamic and versatile content elements that could be easily managed and edited by site editors and administrators.</p>
                                <p>Furthermore, my development approach extended to ensuring the compatibility of these custom components with various WordPress themes and the underlying framework's grid CSS. This meticulous attention to detail ensured that the website not only boasted a visually appealing and user-friendly interface but also adhered to best practices for performance and maintainability. By undertaking both front-end and back-end development, I was able to deliver a holistic digital solution that aligned perfectly with the project's objectives and exceeded the expectations of the stakeholders</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-xs-12 col-lg-6 margin-top'>
                <img  className="project-thumb" src="warren-tablet.png" />
            </div>
            <div className='col-xs-12 col-lg-6'>
                <h3>Warren Southwest Refrigeration</h3>
                <p><a href="https://warrenswrefrigeration.com" target="/ blank">https://warrenswrefrigeration.com</a></p>
                <p>Wordpress & WooCommerce instance with a custom theme offering a creative brochure with focus on preserving the integrity of the Content Management System.</p>
                <h2 className="accordion-header" id="headingWarren">
                    <button className="accordion-button lead collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseWarren" aria-expanded="true" aria-controls="collapseWarren">
                        Read More!
                    </button>
                </h2>
            </div>
            <div className='col-xs-12'>
                <div className="accordion accordion-flush" id="accordionWarren">
                    <div className="accordion-item">
                        <div id="collapseWarren" className="accordion-collapse collapse" aria-labelledby="headingWarren" data-bs-parent="#accordionWarren">
                            <div className="accordion-body">
                                <p><strong>Development Stack used</strong>:</p>
                                <p>Docksal on LAMP stack, MEC Designs Cloud Services</p>
                                <p className="bold">Content Management Solution:</p>
                                <ul>
                                    <li><img className="host" src="wplogo.png" alt="wordpress" /></li>
                                </ul>
                                <p><strong>Hosts</strong>: GoDaddy VPS</p>
                                <p><strong>Challenges</strong></p>
                                <ul>
                                    <li>Export existing Data (250+ products) from current broken website and rebuild missing data</li>
                                    <li>Repair CSS and to get a visual concept of the direction the website was targeting.</li>
                                    <li>Retain as much previous work as possible by debugging all broken assets of original Elementor page builder design into new updated theme using native Gutenburg editor.</li>
                                    <li>CI/CD adhoc requests while restructuring eCommerce data organization.</li>
                                </ul>
                                <p><strong>Solutions Contributed</strong></p>
                                <ul>
                                    <li>Major WP upgrade from 5.8 to ver 6.2.</li>
                                    <li>PHP upgrade from 7.2 to 8.0</li>
                                    <li>Hosting Upgrade to a Virtual Private Server with dedicated resources</li>
                                    <li>Development, Staging, and Production sites utilizing GIT workflow for version control</li>
                                    <li>Creation of new theme based on BootStrap CSS/Javascript Framework</li>
                                    <li>Migration away from Elementor page builder AKA WP Bakery into WP’s native Gutenburg page builder.</li>
                                    <li>Migration of broken content from Elementor into new theme markup offering custom styling.</li>
                                    <li>Custom loop queries to provide non associated content within carousels.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );   
}

export default Wordpress;